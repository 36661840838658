@import 'variables';
@import '~bulma';
@import 'loading';
@import 'observe';
@import 'overlay';
@import 'lock';
@import 'notification';
@import 'datepicker';
@import 'player';
@import 'game';

.button.is-xl {
  font-size: 2rem;
}
.button.is-xxl {
  font-size: 2.5rem;
}

.has-cursor-pointer {
  cursor: pointer;
}

.table.is-sticky {
  thead {
    th {
      background: white;
      position: sticky;
      position: -webkit-sticky;
      top: -2px;
    }
  }
}

.card-header-icon {
  padding-left: 0;
}

.box.is-paddingless {
  div,
  table {
    border-radius: 6px;
  }

  table.is-bordered {
    thead {
      tr:first-child {
        th,
        td {
          border-top-width: 0;
        }
        th:first-child,
        td:first-child {
          border-left-width: 0;
        }
        th:last-child,
        td:last-child {
          border-right-width: 0;
        }
      }
    }

    tbody {
      tr {
        th:first-child,
        td:first-child {
          border-left-width: 0;
        }
        th:last-child,
        td:last-child {
          border-right-width: 0;
        }
      }

      tr:last-child {
        td,
        th {
          border-bottom-width: 0;
        }

        td:first-child,
        th:first-child {
          border-radius: 0 0 0 6px;
        }
        td:last-child,
        th:last-child {
          border-radius: 0 0 6px 0;
        }
      }
    }
  }
}

.button.is-smallish {
  font-size: 0.85rem;
}

.table.is-fixed {
  table-layout: fixed;
}

table.is-hoverable {
  tbody {
    tr:hover {
      background-color: $light !important;
    }
  }
}
tr.is-selected {
  background-color: $light !important;
  color: $light-invert !important;

  th,
  td {
    border-color: #dbdbdb !important;

    &.is-selected {
      background-color: $info-light !important;
      color: $info-light-invert !important;
    }
  }
}

th.is-selected,
td.is-selected {
  background-color: $light !important;
  color: $light-invert !important;
  border-color: #dbdbdb !important;
}

.button.is-text {
  outline: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  text-decoration: none;
}

.select-disable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.Popover {
  z-index: 50;
}

.card-header {
  background-color: $light;
}

.button.is-tiny {
  font-size: 0.65rem;
  border-radius: 2px;
}

html {
  font-size: 0.9rem;
}

@mixin font-pt9-rem {
  font-size: 0.9rem !important;
}

.table.is-small {
  @include font-pt9-rem;
}

.table.is-centered {
  th,
  td {
    text-align: center;
  }
}

section.hero.is-login {
  margin-top: -3.5rem;

  .hero-body {
    align-items: flex-start;

    .container {
      margin-top: 2rem;
    }
  }
}

figure.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}

figure.avatar img {
  height: auto;
  width: 128px;
  border-radius: 10%;
  // padding: 5px;
  background: #fff;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

p.card-header-title.has-text-centered {
  justify-content: center !important;
}

.grid {
  display: grid;

  &.grid-columns-5 {
    grid-template-columns: 40px 40px auto 40px 40px;
  }
}
.ballpark-select-box {
  width: 15em;
  height: 15em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(10, 10, 10, 0.2), 0 0 0 2px rgba(10, 10, 10, 0.2);
  border-radius: 3px;
}
